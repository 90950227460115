<template>
  <v-container class="bg-color-theme supplier-main-create">
    <v-card-text class="white rounded-5 py-5">
      <validation-observer :ref="'observer'" v-slot="{ invalid }">
        <v-form @submit.prevent="submit" lazy-validation autocomplete="off">
          <v-row v-for="field in fields" :key="field.name" class="ma-0">
            <template v-if="field.type == 'text'">
              <v-col
                cols="12"
                :md="field.additional_field ? '6' : '12'"
                class="pa-0"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :rules="field.rules"
                  :name="field.name"
                >
                  <v-label>
                    {{ field.label }}
                    <span
                      v-if="field.rules.includes('required')"
                      class="required-text"
                      >必須</span
                    >
                  </v-label>
                  <v-text-field
                    :placeholder="field.placeholder"
                    dense
                    v-model="field.value"
                    :error-messages="errors"
                    autocomplete="chrome-off"
                    :hint="field.hint"
                    persistent-hint
                    class="font-15px"
                  >
                  </v-text-field>
                </validation-provider>
              </v-col>
            </template>
            <template v-if="field.type == 'dropdown'">
              <v-col
                cols="12"
                :md="field.additional_field ? '6' : '12'"
                class="pa-0"
                :class="field.col_class"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :rules="field.rules"
                  :name="field.name"
                >
                  <v-label>
                    {{ field.label }}
                    <span
                      v-if="field.rules.includes('required')"
                      class="required-text"
                      >必須</span
                    >
                  </v-label>
                  <v-select
                    :placeholder="field.placeholder"
                    dense
                    :items="field.items"
                    :item-text="field.item_text"
                    :item-value="field.item_value"
                    v-model="field.value"
                    :error-messages="errors"
                    :multiple="field.multiple"
                    :chips="field.chip"
                    :small-chips="field.small_chips"
                    :deletable-chips="field.deletable_chips"
                    :class="field.class"
                    class="font-15px"
                  ></v-select>
                </validation-provider>
              </v-col>
            </template>
            <template v-if="field.type == 'textarea'">
              <v-col
                cols="12"
                :md="field.additional_field ? '6' : '12'"
                class="pa-0"
                :class="field.col_class"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :rules="field.rules"
                  :name="field.name"
                >
                  <v-label>{{ field.label }} </v-label>
                  <v-textarea
                    :placeholder="field.placeholder"
                    dense
                    v-model="field.value"
                    :error-messages="errors"
                    :class="field.class"
                    class="font-15px"
                    rows="1"
                    auto-grow
                  ></v-textarea>
                </validation-provider>
              </v-col>
            </template>
          </v-row>
          <v-row class="ma-0">
            <v-col
              class="px-0 pt-0 pb-0 d-flex justify-space-between"
              cols="12"
              md="12"
            >
              <v-btn
                type="button"
                color="white"
                class="primary--text border-primary"
                min-width="146"
                @click.stop="showModal"
              >
                削除する
              </v-btn>
              <v-btn
                type="submit"
                color="primary"
                min-width="146"
                :loading="loading"
                :disabled="invalid"
              >
                更新する
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SupplierCreate",
  data() {
    return {
      loading: false,
      fields: [
        {
          label: "取引先名称",
          name: "business_partner_name",
          type: "text",
          placeholder: "",
          value: "",
          rules: "required:取引先名称"
        },
        {
          label: "表示名",
          name: "display_name",
          type: "text",
          placeholder: "",
          value: "",
          rules: "required:表示名",
          hint: "一覧などに表示する管理しやすい通称を入力してください"
        },
        {
          label: "カテゴリー",
          name: "category",
          type: "dropdown",
          items: this.$store.getters.getMasterData?.partners_category,
          item_text: "value",
          item_value: "id",
          placeholder: "",
          value: "",
          rules: "required:カテゴリー",
          class: "deleteable-chip-dropdown",
          col_class: "pt-4",
          multiple: true,
          chip: true,
          small_chips: true,
          deletable_chips: true
        },
        {
          label: "電話番号",
          name: "telephone_number",
          type: "text",
          placeholder: "",
          value: "",
          rules: "enter_half_width_numbers_hyphens"
        },
        {
          label: "住所",
          name: "address",
          type: "text",
          placeholder: "",
          value: "",
          rules: ""
        },
        {
          label: "ホームページ",
          name: "home_page",
          type: "text",
          placeholder: "",
          value: "",
          rules: "url"
        },
        {
          label: "担当者名",
          name: "contact_person",
          type: "text",
          placeholder: "",
          value: "",
          rules: ""
        },
        {
          label: "メモ",
          name: "note",
          type: "textarea",
          placeholder: "",
          value: "",
          rules: ""
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getSupplier"])
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      this.loading = true;
      this.$store
        .dispatch("SUPPLIER_GET", {
          id: this.$route.params.id
        })
        .finally(() => {
          this.loading = false;
          this.fillFields();
        });
    },
    fillFields() {
      this.fields.forEach(field => {
        if (field.name == "category") {
          field.value = this.getSupplier?.[field.name];
        } else {
          field.value = this.getSupplier?.[field.name];
        }
      });
    },
    showModal() {
      this.$root.confirm
        .open(`この取引先情報を削除しますか？`)
        .then(response => {
          if (response) this.deleteSupplier();
        });
    },
    deleteSupplier() {
      this.loading = true;
      this.$store
        .dispatch("SUPPLIER_DELETE", {
          id: this.$route.params.id
        })
        .then(() => {
          this.$router.push({ name: "SupplierListing" });
          this.$store.commit("showAlert", {
            text: "取引先を削除しました。",
            successStatus: "info"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submit() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }
        this.loading = true;
        const data = {};
        data.id = this.$route.params.id;
        data.shop_id = this.getSupplier.shop_id;
        this.fields.forEach(field => {
          if (field.name == "category") {
            data.category = JSON.stringify(field.value);
          } else if (field.value) {
            data[field.name] = field.value;
          }
        });

        this.$store
          .dispatch("SUPPLIER_UPDATE", data)
          .then(() => {
            this.$router.push({ name: "SupplierListing" });
            this.$store.commit("showAlert", {
              text: "編集内容を保存しました。",
              successStatus: "info"
            });
          })
          .catch(error => {
            if (error?.data?.errors)
              this.$refs.observer.setErrors(error?.data?.errors);
          })
          .finally(() => (this.loading = false));
      });
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scope></style>
