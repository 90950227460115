var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"bg-color-theme supplier-main-create"},[_c('v-card-text',{staticClass:"white rounded-5 py-5"},[_c('validation-observer',{ref:'observer',scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{attrs:{"lazy-validation":"","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_vm._l((_vm.fields),function(field){return _c('v-row',{key:field.name,staticClass:"ma-0"},[(field.type == 'text')?[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":field.additional_field ? '6' : '12'}},[_c('validation-provider',{attrs:{"rules":field.rules,"name":field.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-label',[_vm._v(" "+_vm._s(field.label)+" "),(field.rules.includes('required'))?_c('span',{staticClass:"required-text"},[_vm._v("必須")]):_vm._e()]),_c('v-text-field',{staticClass:"font-15px",attrs:{"placeholder":field.placeholder,"dense":"","error-messages":errors,"autocomplete":"chrome-off","hint":field.hint,"persistent-hint":""},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]}}],null,true)})],1)]:_vm._e(),(field.type == 'dropdown')?[_c('v-col',{staticClass:"pa-0",class:field.col_class,attrs:{"cols":"12","md":field.additional_field ? '6' : '12'}},[_c('validation-provider',{attrs:{"rules":field.rules,"name":field.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-label',[_vm._v(" "+_vm._s(field.label)+" "),(field.rules.includes('required'))?_c('span',{staticClass:"required-text"},[_vm._v("必須")]):_vm._e()]),_c('v-select',{staticClass:"font-15px",class:field.class,attrs:{"placeholder":field.placeholder,"dense":"","items":field.items,"item-text":field.item_text,"item-value":field.item_value,"error-messages":errors,"multiple":field.multiple,"chips":field.chip,"small-chips":field.small_chips,"deletable-chips":field.deletable_chips},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]}}],null,true)})],1)]:_vm._e(),(field.type == 'textarea')?[_c('v-col',{staticClass:"pa-0",class:field.col_class,attrs:{"cols":"12","md":field.additional_field ? '6' : '12'}},[_c('validation-provider',{attrs:{"rules":field.rules,"name":field.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-label',[_vm._v(_vm._s(field.label)+" ")]),_c('v-textarea',{staticClass:"font-15px",class:field.class,attrs:{"placeholder":field.placeholder,"dense":"","error-messages":errors,"rows":"1","auto-grow":""},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]}}],null,true)})],1)]:_vm._e()],2)}),_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"px-0 pt-0 pb-0 d-flex justify-space-between",attrs:{"cols":"12","md":"12"}},[_c('v-btn',{staticClass:"primary--text border-primary",attrs:{"type":"button","color":"white","min-width":"146"},on:{"click":function($event){$event.stopPropagation();return _vm.showModal.apply(null, arguments)}}},[_vm._v(" 削除する ")]),_c('v-btn',{attrs:{"type":"submit","color":"primary","min-width":"146","loading":_vm.loading,"disabled":invalid}},[_vm._v(" 更新する ")])],1)],1)],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }